import { Component, OnInit, OnDestroy, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Subject, from, BehaviorSubject, throwError } from 'rxjs';
import { tap, takeUntil, switchMap, catchError, filter, finalize, delay } from 'rxjs/operators';
import { StepperComponent, UnitConfirmationDialogComponent } from '@core/components';
import { TypedFile, ParsedContent, EvaluateResponse, BatterPeakShavingResult } from '@core/interfaces';
import { XlsxReaderService } from '@core/services/xlsx-reader.service';
import { FileFormat, Unit } from '@core/enums';
import { CsvReaderService } from '@core/services/csv-reader.service';
import { TimeseriesParserService } from '@core/services/timeseries-parser.service';
import { LineChartComponent } from 'src/app/charts/components';
import { getPoolingLineChartConfig, getPeakshavingStackedChart } from '@core/config';
import { DecimalPipe } from '@angular/common';
import { ChartData, StackedChartData } from 'src/app/charts/interfaces';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { PeakshavingPricerService } from '@core/services/peakshaving-pricer.service';
import { AlertComponent } from 'src/app/local-ui-elements/components';
import { I18nService } from '@astron/i18n';
import { MatDialog } from '@angular/material';
import { HttpErrorResponse } from '@angular/common/http';
import { ButtonGroupComponent } from '@alpiq/ui-elements';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'alp-peakshaving-pricer-view',
  templateUrl: './peakshaving-pricer.component.html',
  styleUrls: ['./peakshaving-pricer.component.scss'],
})
export class PeakshavingPricerComponent implements OnInit, OnDestroy {
  private onDestroy: Subject<void> = new Subject();

  private supplierId: string = '';

  public steps: string[] = ['step::upload', 'step::verify', 'step::results'];
  public stepChanges: Subject<number> = new Subject();

  public fileUploaded: Subject<TypedFile> = new Subject();

  public currentStep: number = 0;

  public nextDisabled: boolean = true;

  @ViewChild('stepper')
  public stepper: StepperComponent;

  @ViewChild('lineChart')
  public lineChart: LineChartComponent;

  @ViewChild('alert')
  public alert: AlertComponent;

  @ViewChild('xxx')
  public buttonGroup: ButtonGroupComponent;

  public chartDataChange: BehaviorSubject<ChartData> = new BehaviorSubject(null);

  public chartConfig: BehaviorSubject<Highcharts.Options> = new BehaviorSubject({});
  public peakshavingChartConfig: BehaviorSubject<Highcharts.Options> = new BehaviorSubject({});

  public peakshavingDataChange: BehaviorSubject<StackedChartData>[] = [];

  public tariffForm: FormGroup = this.fb.group({
    tariff: [
      0,
      Validators.compose([Validators.required, Validators.min(1), Validators.pattern(/^[0-9]*(\.|,)?[0-9]{0,2}$/)]),
    ],
    period: [{ value: 'Monthly' }, Validators.required],
  });
  public tariffUnit: string = '';

  public isLoading: boolean = false;

  public batteryPeakShavingResults: EvaluateResponse['batteryPeakShavingResults'] = null;
  public hours: string[] = Array.from({ length: 24 }).map((_, i) => (i < 10 ? `0${i}:00` : `${i}:00`));

  public shownImageIndex: number = 0;
  private imageChangeInterval = null;

  constructor(
    public dialog: MatDialog,
    private xlsxReaderService: XlsxReaderService,
    private csvReaderService: CsvReaderService,
    private timeseriesParserService: TimeseriesParserService,
    private peakshavingPricerService: PeakshavingPricerService,
    private changeDetectorRef: ChangeDetectorRef,
    private i18nNumberPipe: DecimalPipe,
    private fb: FormBuilder,
    private i18nService: I18nService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    window['view'] = this;

    this.startImageChanges();

    this.route.paramMap.pipe(takeUntil(this.onDestroy)).subscribe(params => {
      if (params.get('id')) {
        this.supplierId = params.get('id');
      }
    });

    this.stepChanges
      .pipe(
        tap(step => {
          if (step === 0) {
            // on first page we reset things back to default.
            this.timeseriesParserService.reset();
            this.chartDataChange.next(null);
            this.tariffForm.patchValue({ tariff: 0, period: { value: 'Monthly' } });
          } else if (step === 1) {
            window.setTimeout(() => {
              this.tariffForm.controls.period.setValue(this.tariffForm.controls['period'].value);
            }, 0);
          } else if (step === 2) {
            this.peakshavingPricerService.saveTariff(this.tariffForm.get('tariff').value);
            this.handleResults();
          }
        }),
        takeUntil(this.onDestroy)
      )
      .subscribe();

    this.fileUploaded
      .pipe(
        tap(() => (this.isLoading = true)),
        delay(100), // needed for showing the loader
        filter(file => file !== null),
        switchMap(typedFile => {
          if (typedFile.type === FileFormat.XLSX) {
            return from(this.xlsxReaderService.getContent(typedFile.file));
          } else if (typedFile.type === FileFormat.CSV) {
            return from(this.csvReaderService.getContent(typedFile.file));
          }
        }),
        tap(result => {
          this.isLoading = false;
          if (result === null) {
            this.alert.showMultilineMessage(
              this.i18nService.translate('label::error::invalid-structure', 'peakshaving-pricer'),
              this.i18nService.translate('label::error::eg-structure-header', 'peakshaving-pricer'),
              this.i18nService.translate('label::error::eg-structure-row', 'peakshaving-pricer')
            );
          } else {
            this.stepper.selectNextStep(true);
            this.changeDetectorRef.detectChanges();
            this.showUnitConfirmation();
          }
        }),
        takeUntil(this.onDestroy)
      )
      .subscribe();

    this.fileUploaded
      .pipe(
        filter(file => file === null),
        tap(() => {
          this.alert.showError('label::error::invalid-file');
          this.isLoading = false;
        }),
        takeUntil(this.onDestroy)
      )
      .subscribe();
  }

  public downloadTemplate() {
    location.href = '/assets/templates/load_profile_template.csv';
  }

  public toggleRowDropdown(battery: BatterPeakShavingResult) {
    if (!this.peakshavingDataChange[battery.name]) {
      this.peakshavingDataChange[battery.name] = new BehaviorSubject({});
    }

    if (!battery.opened) {
      const startDate = this.timeseriesParserService.current.timeseries[0].start;
      battery.extra = { headers: [] };
      battery.extra.headers = this.generateBatteryHeaders(battery, this.tariffForm.value.period.value, startDate);

      this.peakshavingChartConfig.next({
        xAxis: {
          categories: battery.extra.headers,
        },
      });

      this.peakshavingDataChange[battery.name].next({
        series: [
          {
            name: this.i18nService.translate('label::result::peak-reduction', 'peakshaving-pricer'),
            showInLegend: false,
            data: battery.peak_reductions,
          },
          {
            name: this.i18nService.translate('label::result::graph-peak-thresholds', 'peakshaving-pricer'),
            showInLegend: false,
            data: battery.peak_thresholds,
          },
        ],
      });
    }

    battery.opened = !battery.opened;
  }

  private generateBatteryHeaders(
    battery: BatterPeakShavingResult,
    period: 'Monthly' | 'Yearly',
    start: Date
  ): string[] {
    if (period === 'Monthly') {
      let month = start.getMonth();
      return battery.peak_reductions.map(() => {
        const monthStr = this.i18nService.translate(`month::${month}`, 'common');
        month === 11 ? (month = 0) : month++;
        return monthStr;
      });
    } else {
      let year = start.getFullYear() - 1;
      return battery.peak_reductions.map((_, i) => {
        year++;
        return `${year}`;
      });
    }
  }

  private showUnitConfirmation() {
    this.dialog
      .open<UnitConfirmationDialogComponent>(UnitConfirmationDialogComponent, {
        disableClose: true,
        data: { unit: this.timeseriesParserService.current.unit },
      })
      .afterClosed()
      .pipe(
        tap(result => {
          this.timeseriesParserService.current.unit = result.unit;

          // we need to re-set the value, because otherwise the button-group doesn't know which is selected
          this.tariffForm.controls.period.setValue(this.tariffForm.controls['period'].value);

          // update the chart as well.
          this.chartConfig.next(getPoolingLineChartConfig(this.i18nNumberPipe, 'kW'));
          this.sendDataToChart(this.timeseriesParserService.current);
          this.changeDetectorRef.detectChanges();

          this.tariffUnit = this.peakshavingPricerService.getUnitWithoutHour(result.unit);
        })
      )
      .subscribe();
  }

  private handleResults(): any {
    this.isLoading = true;

    this.peakshavingChartConfig.next(getPeakshavingStackedChart());

    const content = this.timeseriesParserService.current;
    this.peakshavingPricerService
      .calculatePrice(
        this.supplierId,
        content.unit,
        content.timeseries[0].start,
        this.timeseriesParserService.periodMinutes,
        this.tariffForm.value.period.value,
        content.timeseries.map(ts => ts.value)
      )
      .pipe(
        tap(() => (this.isLoading = false)),
        catchError((networkError: HttpErrorResponse) => {
          /**
           * From the previous code it seems the API returns naked error messages, however if there is an internal error
           * the default 500 error returned by AWS is wrapped in an object, so we first try to read the internal error and if there
           * is no such property then we assume its a naked string, if empty, we stringify the whole network error.
           */
          const errorMessage =
            (networkError.error && networkError.error.message) || networkError.error || JSON.stringify(networkError);

          this.alert.showErrorMessage(errorMessage);
          this.changeDetectorRef.detectChanges();

          return throwError(networkError);
        }),
        tap(result => {
          // By request need to round the capacity and annual saving numbers to two digits
          this.batteryPeakShavingResults = result.batteryPeakShavingResults.map(row => ({
            // the + remove any extra zeroes from the end: 1.501 => 1.50 => 1.5
            ...row,
            annualSavings: +row.annualSavings.toFixed(1),
            ratedCapacity: +row.ratedCapacity.toFixed(2),
            opened: false,
            extra: {
              headers: [],
            },
          }));
        }),
        finalize(() => (this.isLoading = false))
      )
      .subscribe();
  }

  private sendDataToChart(content: ParsedContent) {
    const fromDate = content.timeseries[0].start.getTime();
    const to = content.timeseries[content.timeseries.length - 1].end.getTime();

    const factor = this.getUnitFactor(content.unit);

    this.chartDataChange.next({
      from: fromDate,
      to,
      series: [
        {
          name: 'Timeseries #1',
          showInLegend: false,
          data: <[number, number][]>content.timeseries.map(ts => {
            // must show the data in kW, therefore it needs to be converted using the factor
            return [ts.start.getTime(), ts.value * factor];
          }),
          secondary: false,
          unit: content.unit,
        },
      ],
    });

    // we need to tigger a change detection to show the chart
    this.changeDetectorRef.detectChanges();
  }

  /**
   * Gives back the factor for a unit, which determines how it can be converted into kW
   */
  private getUnitFactor(unit: Unit): number {
    switch (unit) {
      case Unit.MW:
        return 1000;
      case Unit.MWh:
        return 4000;
      case Unit.kWh:
        return 4;
      case Unit.kW:
        return 1;
      default:
        console.error('[getUnitFactor] Unknown unit.');
    }
  }

  /**
   * Change the images every 10 seconds
   */
  private startImageChanges() {
    this.imageChangeInterval = setInterval(() => {
      this.shownImageIndex++;
      if (this.shownImageIndex === 3) {
        this.shownImageIndex = 0;
      }
    }, 10000);
  }

  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.unsubscribe();
    if (this.imageChangeInterval !== null) {
      clearInterval(this.imageChangeInterval);
    }
  }
}
