import { Injectable } from '@angular/core';
import { NetworkService } from '@astron/network';
import { Observable, of } from 'rxjs';
import { EvaluateResponse } from '@core/interfaces';
import { Unit } from '@core/enums';
import { map } from 'rxjs/operators';
import { mockResult } from '@core/mocks';
import { I18nService } from '@astron/i18n';
import { format } from 'date-fns';

@Injectable({
  providedIn: 'root',
})
export class PeakshavingPricerService {
  private tariff: number;

  constructor(private network: NetworkService, private i18n: I18nService) {}

  /**
   * Saves the tariff from it's string representation into a floating number in the service.
   *
   * The separator could be both comma and period.
   *
   * @param tariff the tariff as numerical string
   */
  public saveTariff(tariff: string) {
    this.tariff = Number.parseFloat(tariff.replace(',', '.'));
  }

  public calculatePrice(
    supplierId: string,
    unit: Unit,
    startDate: Date,
    periodMinutes: number,
    peakPeriod: string,
    values: number[]
  ): Observable<EvaluateResponse> {
    return this.network
      .post<EvaluateResponse>(
        '/evaluate',
        {
          requestID: this.getRandomId(),
          startTimestamp: format(startDate, 'YYYY-MM-DDTHH:mm:ssZZ'),
          unit,
          periodMinutes,
          values,
          latitude: 0, // TODO using hardcoded strings, until told otherwise
          longitude: 0, // TODO using hardcoded strings, until told otherwise
          peakCost: this.tariff,
          peakUnit: this.getUnitWithoutHour(unit),
          peakPeriod,
          currency: '€', // TODO using hardcoded strings, until told otherwise
          supplierId,
        },
        undefined,
        {
          withCredentials: false,
        }
      )
      .pipe(
        // catchError(() => of(mockResult)),
        map(response => {
          const resultsWithRoi = response.batteryPeakShavingResults.map(result => ({
            ...result,
            roiNumber: result.totalCost / result.annualSavings,
          }));
          const sortedResults = resultsWithRoi.sort((a, b) => a.roiNumber - b.roiNumber);

          return {
            ...response,
            batteryPeakShavingResults: sortedResults
              .map(result => {
                const roiStr = result.roiNumber.toFixed(2) + ' ' + this.getYearString(result.roiNumber);
                return {
                  ...result,
                  ROI: roiStr,
                };
              })
              // Show only the first 20 results
              .filter((v, i) => i < 20),
          };
        })
      );
  }

  public getUnitWithoutHour(unit: Unit): Unit {
    let peakUnit: Unit;
    if (unit === Unit.kW || unit === Unit.kWh) {
      peakUnit = Unit.kW;
    }
    if (unit === Unit.MW || unit === Unit.MWh) {
      peakUnit = Unit.MW;
    }
    return peakUnit;
  }

  private getYearString(year: number) {
    return year === 1 ? this.i18n.translate('year', 'common') : this.i18n.translate('years', 'common');
  }

  private getRandomId() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      // tslint:disable-next-line:no-bitwise
      const r = (Math.random() * 16) | 0;
      // tslint:disable-next-line:no-bitwise
      const v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }
}
