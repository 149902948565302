import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import * as Highcharts from 'highcharts';

import * as Components from './components';

const COMPONENTS = [Components.LineChartComponent, Components.PieChartComponent];

@NgModule({
  declarations: [...COMPONENTS],
  exports: [...COMPONENTS],
  imports: [BrowserModule],
})
export class ChartsModule {
  constructor() {
    Highcharts.wrap((<any>Highcharts).seriesTypes.pie.prototype, 'render', <any>function(proceed) {
      proceed.call(this);
      this.setTitle(this.options.title);
    });
  }
}
