import { Injectable } from '@angular/core';

import * as XLSX from 'xlsx';
import { ParsedContent } from '@core/interfaces';
import { TimeseriesParserService } from './timeseries-parser.service';

@Injectable({
  providedIn: 'root',
})
export class XlsxReaderService {
  private readAsBinary: boolean = true;

  constructor(private timeseriesParser: TimeseriesParserService) {}

  public async getContent(file: File): Promise<ParsedContent | null> {
    const workbook = await this.getWorkbook(file);
    try {
      return this.parse(workbook, this.parseAsJson(workbook));
    } catch {
      return null;
    }
  }

  private getWorkbook(file: File): Promise<XLSX.WorkBook> {
    return new Promise(resolve => {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        let data = e.target.result;

        if (!this.readAsBinary) {
          data = new Uint8Array(data);
        }

        const workbook = XLSX.read(data, { type: this.readAsBinary ? 'binary' : 'array', cellDates: true });
        resolve(workbook);
      };

      this.readAsBinary ? reader.readAsBinaryString(file) : reader.readAsArrayBuffer(file);
    });
  }

  private parseAsJson(workbook: XLSX.WorkBook): any {
    const sheetNames = workbook.SheetNames;
    return XLSX.utils.sheet_to_json(workbook.Sheets[sheetNames[0]], { raw: false });
  }

  private parse(workbook: XLSX.WorkBook, json: any[]): ParsedContent {
    const firstHeader = workbook.Sheets[workbook.SheetNames[0]].A1.v;
    const secondHeader = workbook.Sheets[workbook.SheetNames[0]].B1.v;
    const thirdHeader = workbook.Sheets[workbook.SheetNames[0]].C1.v;

    const timeseries: [Date, Date, string][] = json.map(
      row => <[Date, Date, string]>[new Date(row[firstHeader]), new Date(row[secondHeader]), row[thirdHeader]]
    );

    return this.timeseriesParser.parse(timeseries, thirdHeader);
  }
}
