import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonModule, TopMenuModule, TabModule, ButtonGroupModule } from '@alpiq/ui-elements';

const MODULES = [FormsModule, ReactiveFormsModule, ButtonModule, TopMenuModule, TabModule, ButtonGroupModule];

@NgModule({
  imports: MODULES,
  exports: MODULES,
})
export class SharedModule {}
