import { Component, Input, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'alp-stepper',
  templateUrl: 'stepper.component.html',
  styleUrls: ['stepper.component.scss'],
})
export class StepperComponent implements OnDestroy {
  private onDestroy: Subject<void> = new Subject();

  private _currentStep: number = 0;

  @Input('steps')
  public steps: string[] = [];

  @Input('stepChanges')
  public stepChanges: Subject<number> = new Subject();

  @Input('nextDisabled')
  public nextDisabled: boolean = false;

  public get currentStep() {
    return this._currentStep;
  }

  /**
   * Selects the specified step.
   *
   * @param index the index of the step
   * @param force allows selecting disabled steps
   */
  public selectStep(index: number, force: boolean = false) {
    if (force || this.stepAllowed(index)) {
      this._currentStep = index;
      this.stepChanges.next(index);
    }
  }

  /**
   * Selects the next step.
   *
   * @param force allows selecting disabled steps
   */
  public selectNextStep(force: boolean = false) {
    this.selectStep(this.currentStep + 1, force);
  }

  /**
   * Decides if the step is allowed to click on or not. Basic rules are:
   *  - past steps are always allowed
   *  - current step is always allowed
   *  - next step is allowed if nextDisabled is false
   *  - every future step after the next step is not allowed
   *
   * @param newStepIndex the index of the step we check
   */
  stepAllowed(newStepIndex: number) {
    return this.currentStep >= newStepIndex || (!this.nextDisabled && this.currentStep + 1 === newStepIndex);
  }

  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.unsubscribe();
  }
}
