import { Component, Input } from '@angular/core';
import { I18nService } from '@astron/i18n';

@Component({
  selector: 'alp-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
})
export class AlertComponent {
  public active: boolean = false;

  public title: string = '';
  public message: string = '';
  public multilineMessage: string[] = [];

  public readonly errorTimeout = 5000;

  private defaultTitleKey: string = 'label::error::title';

  constructor(private i18nService: I18nService) {}

  public showError(messageKey: string, titleKey: string = this.defaultTitleKey) {
    this.multilineMessage = [];
    this.message = this.i18nService.translate(messageKey, 'peakshaving-pricer');
    this.title = this.i18nService.translate(titleKey, 'peakshaving-pricer');

    this.show();
  }

  public showErrorMessage(message: string) {
    this.multilineMessage = [];
    this.message = message;
    this.title = this.i18nService.translate(this.defaultTitleKey, 'peakshaving-pricer');

    this.show();
  }

  public showMultilineMessage(...messages: string[]) {
    this.message = '';
    this.multilineMessage = messages;
    this.title = this.i18nService.translate(this.defaultTitleKey, 'peakshaving-pricer');

    this.show(this.errorTimeout * 2);
  }

  public hide() {
    this.active = false;
  }

  private show(timeout: number = this.errorTimeout) {
    this.active = true;

    window.setTimeout(() => {
      this.active = false;
    }, timeout);
  }
}
