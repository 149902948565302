import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatFormFieldModule, MatInputModule } from '@angular/material';
import { I18nModule } from '@astron/i18n';
import { AlpiqElementsModule } from '@alpiq/ui-elements';

import * as Components from './components';

const COMPONENTS = [Components.SpinnerLoaderComponent, Components.TableComponent, Components.AlertComponent];

@NgModule({
  declarations: [...COMPONENTS],
  entryComponents: [...COMPONENTS],
  exports: [...COMPONENTS],
  imports: [
    BrowserAnimationsModule,
    MatInputModule,
    MatFormFieldModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    I18nModule.forChild(),
    AlpiqElementsModule,
  ],
})
export class LocalUIComponentsModule {}
