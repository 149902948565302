import * as Highcharts from 'highcharts';

export const chartConfig = <Highcharts.Options>{
  title: undefined,
  chart: {
    type: 'column',
    plotBorderColor: '#FFF',
    spacingRight: 0,
    spacingLeft: 0,
    plotBackgroundColor: null,
    plotBorderWidth: 1,
    plotShadow: false,
  },
  time: {
    timezoneOffset: new Date().getTimezoneOffset(),
  },
  colors: ['#EB8C2C', '#053043', '#053043'],
  plotOptions: {
    series: {
      marker: {
        enabled: false,
      },
    },
    column: {
      stacking: 'normal',
    },
  },
  credits: {
    enabled: false,
  },
  tooltip: {
    headerFormat: `<b>{point.x}</b><br/>`,
    pointFormatter: function() {
      return `${this.series.name}: ${this.y.toFixed(1)}<br/>`;
    },
  },
};
