import { Injectable } from '@angular/core';
import { ParsedContent } from '@core/interfaces';
import { Unit } from '@core/enums';
import parseDecimalNumber from 'parse-decimal-number';

@Injectable({
  providedIn: 'root',
})
export class TimeseriesParserService {
  private _current: ParsedContent = null;
  private _periodMinutes: number = 0;

  public get current(): ParsedContent {
    return this._current;
  }

  public get periodMinutes(): number {
    return this._periodMinutes;
  }

  /**
   * Returns true if everything is set in the service.
   */
  public get stateComplete(): boolean {
    return this.current && this.current.unit && true;
  }

  /**
   * Parses the file content into the unified ParsedContent format.
   *
   * @param timeseries array of date-value pair arrays
   * @param thirdHeader name of the third column
   */
  public parse(timeseries: [string | Date, string | Date, string][], thirdHeader: string): ParsedContent {
    const unit = this.getValue(thirdHeader);

    // remove the space, backtick and single quote from the string, as they are unwanted in the parsing
    const normalizedFloats = timeseries.map(f => {
      if (typeof f[2] === 'number') {
        return f[2];
      }
      return f[2].replace(/['’ ]/g, '');
    });

    const thousandDecimalSeparator = this.guessThousandDecimalSeparator(normalizedFloats);

    this._current = {
      timeseries: timeseries.map((row, index) => ({
        start: this.parseDate(row[0]),
        end: this.parseDate(row[1]),
        value: this.parseFloat(normalizedFloats[index], thousandDecimalSeparator),
      })),
      unit,
    };

    this._periodMinutes =
      (this.current.timeseries[0].end.getTime() - this.current.timeseries[0].start.getTime()) / 1000 / 60;

    return this.current;
  }

  /**
   * Guess the thousand and the decimal separator in an array of strings
   * by trying to parse the whole array with `.` as thousand separator and `,` as decimal separator.
   *
   * If the parsing fails for any number, than the decimal separator will be `.` and the thousands separator
   * will be `,`
   *
   * @param floatStr an array of floats for guessing the separators
   * @returns `.,` or `,.`
   */
  private guessThousandDecimalSeparator(floatStr: (string | number)[]): string {
    if (floatStr.some(f => typeof f !== 'number' && isNaN(parseDecimalNumber(f, '.,')))) {
      return ',.';
    }
    return '.,';
  }

  /**
   * Parses an array of float string to floats with a given thousand and decimal separator.
   * @param floatStr An array of float strings to parse.
   * @param thousandDecimalSeparator Given separators for external library. Can be `,.` or `.,`. First is thousands, second is decimal.
   */
  private parseFloat(floatStr: string | number, thousandDecimalSeparator: string): number {
    if (typeof floatStr === 'number') {
      return floatStr;
    }
    const a = parseDecimalNumber(floatStr, thousandDecimalSeparator);
    return a;
  }

  private getValue(header: string): Unit {
    try {
      const matches = /.*\(([a-zA-Z]+)\)/.exec(header);
      if (Object.keys(Unit).indexOf(matches[1]) > -1) {
        return <Unit>matches[1];
      }
      return null;
    } catch {
      return null;
    }
  }

  private parseDate(date: Date | string): Date {
    if (date instanceof Date) {
      return date;
    }
    const matches = /([0-9]{2})\.([0-9]{2})\.([0-9]{4}) ([0-9]{2})\:([0-9]{2})/.exec(date);
    return new Date(
      Number.parseInt(matches[3]),
      Number.parseInt(matches[2]) - 1,
      Number.parseInt(matches[1]),
      Number.parseInt(matches[4]),
      Number.parseInt(matches[5])
    );
  }

  /**
   * Removes all State stored in the service.
   */
  public reset() {
    this._current = null;
    this._periodMinutes = 0;
  }
}
