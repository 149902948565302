import { Component, Inject, ChangeDetectorRef, AfterContentInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Unit } from '@core/enums';
import { FormGroup, FormBuilder } from '@angular/forms';
import { tap } from 'rxjs/operators';
import { I18nService } from '@astron/i18n';

@Component({
  selector: 'alp-unit-confirmation-dialog',
  templateUrl: './unit-confirmation-dialog.component.html',
  styleUrls: ['./unit-confirmation-dialog.component.scss'],
})
export class UnitConfirmationDialogComponent implements AfterContentInit {
  public units: Unit[] = <Unit[]>Object.keys(Unit);

  public selectedUnit: { value: Unit } = { value: Unit.kW };

  public unitForm: FormGroup = this.fb.group({
    unit: [''],
  });

  public unitRecognizedFromFile: boolean = false;
  public recognizedUnitDescription: string = '';

  constructor(
    public dialog: MatDialogRef<UnitConfirmationDialogComponent, { confirm: boolean; unit: Unit }>,
    @Inject(MAT_DIALOG_DATA) public data: { unit: Unit },
    private fb: FormBuilder,
    private detection: ChangeDetectorRef,
    private i18n: I18nService
  ) {
    if (data.unit !== null) {
      this.selectedUnit.value = data.unit;
      this.unitRecognizedFromFile = true;
      this.recognizedUnitDescription = this.i18n.translate(
        'label::unit-confirmation::recognized-unit',
        'peakshaving-pricer',
        { unit: data.unit }
      );
    }
  }

  ngAfterContentInit() {
    this.detection.detectChanges();
    this.unitForm.setValue({
      unit: this.selectedUnit,
    });
  }

  public close(confirm: boolean = false) {
    this.dialog.close({ confirm, unit: this.unitForm.value.unit.value });
  }
}
