import { NativeDateAdapter } from "@angular/material";

/**
 * Extend Material admin's date adapter
 */
export class CustomDateAdapter extends NativeDateAdapter {
  /**
   * Set Monday as the first day of the week
   */
  public getFirstDayOfWeek(): number {
    return 1;
  }
}
