import { Component, Input } from '@angular/core';

@Component({
  selector: 'alp-spinner-loader',
  templateUrl: './spinner-loader.component.html',
  styleUrls: ['./spinner-loader.component.scss'],
})
export class SpinnerLoaderComponent {
  @Input('size')
  public size: number = 10;
}
