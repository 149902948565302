import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from "@angular/router";
import { Observer, Observable, forkJoin } from "rxjs";

import { I18nService } from "@astron/i18n";

@Injectable()
export class AppLoader implements CanActivate {
  constructor(private i18nService: I18nService) {}

  public canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return new Observable<boolean>((responseObserver: Observer<boolean>) => {
      forkJoin([
        new Observable<boolean>((obs: Observer<boolean>) => {
          this.i18nService.ready.subscribe(ready => {
            if (ready) {
              obs.next(true);
              obs.complete();
            }
          });
        })
      ]).subscribe(null, null, () => {
        responseObserver.next(true);
        responseObserver.complete();
      });
    });
  }
}
