import { DecimalPipe } from '@angular/common';
import { chartConfig } from '../../charts/components/line-chart/line-chart.config';
import { format } from 'date-fns';

export const getPeakshavingStackedChart: () => Highcharts.Options = () => {
  return {
    ...chartConfig,
    chart: {
      ...chartConfig.chart,
      type: 'column',
    },
    tooltip: {
      formatter: function() {
        const pointsLabel = this.points
          .map(point => {
            return `<tr>
              <td class="chart-tooltip__title"></td>
              <td class="chart-tooltip__unit" style="font-weight: bold; text-align: right">${point.y}</td>
              <td class="chart-tooltip__date">at ${format(this.x, 'YYYY-MM-DD HH:mm:ss')}</td>
            </tr>`;
          })
          .join('');

        return `
        <div class="chart-tooltip"
        style="font-family: VistaSansOTCE-Med; color: #053043; font-size: 12px; padding-left: 5px; padding-right: 5px;">
          <table>
            <tbody>
              ${pointsLabel}
            </tbody>
          </table>
        </div>`;
      },
      shared: true,
      outside: true,
      useHTML: true,
      borderWith: 1,
      padding: 0,
      borderRadius: 0,
      borderColor: '#053043',
      backgroundColor: '#ffffff',
      shape: 'square',
      shadow: false,
    },
    yAxis: {
      labels: {
        formatter: function() {
          return `${this.value}`;
        },
      },
    },
  };
};
