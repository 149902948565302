import { Component, ViewChild, ElementRef, OnDestroy, Input, AfterViewInit } from '@angular/core';
import { Subject, BehaviorSubject, fromEvent } from 'rxjs';
import { tap, takeUntil, debounceTime, filter, startWith } from 'rxjs/operators';
import { chart } from 'highcharts';

import { chartConfig } from './stacked-chart.config';
import { StackedChartData } from '../../interfaces';

@Component({
  selector: 'alp-stacked-chart',
  templateUrl: './stacked-chart.component.html',
  styleUrls: ['./stacked-chart.component.scss'],
})
export class PieChartComponent implements AfterViewInit, OnDestroy {
  private onDestroy: Subject<void> = new Subject();

  @Input('data')
  public data: Subject<StackedChartData> = new Subject();

  @Input('config')
  public customConfig: BehaviorSubject<Highcharts.Options> = new BehaviorSubject({});

  @ViewChild('stackedChart')
  public stackedChart: ElementRef;

  private chart: Highcharts.ChartObject;

  public ngAfterViewInit() {
    this.chart = chart(this.stackedChart.nativeElement, {
      ...chartConfig,
      plotOptions: {
        ...chartConfig.plotOptions,
        series: {
          ...chartConfig.plotOptions.series,
          point: {
            ...chartConfig.plotOptions.series.point,
          },
        },
      },
    });

    // Update chart config on change
    this.customConfig
      .pipe(
        tap(config => this.chart.update(config)),
        takeUntil(this.onDestroy)
      )
      .subscribe();

    /**
     * Handle when data is passed to the Chart
     */
    this.data
      .pipe(
        tap(data => {
          if (this.chart.series.length > 0) {
            this.removeAllSeries();
          }
          data.series.forEach(pieSeries => {
            this.chart.addSeries(pieSeries);
          });
        }),
        tap(() => {
          this.chart.redraw();
        }),
        takeUntil(this.onDestroy)
      )
      .subscribe();

    /**
     * Resize the chart to support responsivness.
     */
    fromEvent(window, 'resize')
      .pipe(
        startWith(null),
        debounceTime(50),
        tap(() => {
          // const boundingClientRect = (<HTMLDivElement>this.lineChart.nativeElement).getBoundingClientRect();
          // TODO: This is ugly, but I cannot make the chart container fill it's correctly sized component host element.
          const boundingClientRect = document.getElementsByTagName('alp-stacked-chart')[0].getBoundingClientRect();

          // a modifier needs to be added, so the graph doesn't overflow it's parent
          const overflowModifier = 50;
          this.resize(boundingClientRect.width - overflowModifier, boundingClientRect.height);
        }),
        takeUntil(this.onDestroy)
      )
      .subscribe();
  }

  public resize(width: number, height?: number): void {
    this.chart.update({
      chart: {
        width,
        height,
      },
    });
  }

  public reflow(): void {
    this.chart.reflow();
  }

  private removeAllSeries(): void {
    while (this.chart.series.length > 0) {
      this.chart.series[0].remove(true);
    }
  }

  public ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.unsubscribe();
  }
}
