import * as Highcharts from 'highcharts';
import { format } from 'date-fns';

export const chartConfig = <Highcharts.Options>{
  title: undefined,
  tooltip: {
    formatter: function() {
      return `<span style="color: ${(<any>this.series).color}">${this.series.name}</span>: <strong>${
        this.y
      }</strong> at ${format(this.x, 'YYYY-MM-DD HH:mm:ss')}`;
    },
  },
  chart: {
    type: 'line',
    plotBorderColor: '#FFF',
    plotBorderWidth: 1,
    spacingRight: 0,
    spacingLeft: 0,
  },
  xAxis: {
    dateTimeLabelFormats: {
      millisecond: '%H:%M:%S.%L',
      second: '%H:%M:%S',
      minute: '%H:%M',
      hour: '%H:%M',
      day: '%e. %b',
      week: '%e. %b',
      month: "%b '%y",
      year: '%Y',
    },
    type: 'datetime',
  },
  time: {
    timezoneOffset: new Date().getTimezoneOffset(),
    useUTC: false,
  },
  plotOptions: {
    series: {
      marker: {
        enabled: false,
      },
    },
  },
  credits: {
    enabled: false,
  },
  yAxis: {
    title: {
      text: undefined,
    },
  },
};
