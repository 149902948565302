import { Injectable } from '@angular/core';
import { FileFormatMap } from '../maps/file-format.map';
import { FileFormat } from '@core/enums';
import { TypedFile } from '@core/interfaces';

@Injectable({
  providedIn: 'root',
})
export class FileGuesserService {
  public guessFileType(file: File, fileExtensions: FileFormat[]): TypedFile | null {
    const fileNameParts = file.name.split('.');
    const extension: string = fileNameParts[fileNameParts.length - 1].toLowerCase().trim();

    const fileTypeIndex = (<string[]>fileExtensions).indexOf(extension);

    if (fileTypeIndex > -1) {
      if (this.validateMimeType(file, fileExtensions[fileTypeIndex])) {
        return {
          file,
          type: fileExtensions[fileTypeIndex],
        };
      }
    }

    return null;
  }

  private validateMimeType(file: File, fileExtension: FileFormat): boolean {
    const mimeTypes = FileFormatMap[fileExtension] || 'undefined';

    return mimeTypes.includes(file.type);
  }
}
