import { Injectable } from '@angular/core';
import { TimeseriesParserService } from './timeseries-parser.service';
import { ParsedContent } from '@core/interfaces';

@Injectable({
  providedIn: 'root',
})
export class CsvReaderService {
  constructor(private timeseriesParser: TimeseriesParserService) {}

  public getContent(file: File): Promise<ParsedContent | null> {
    return new Promise(resolve => {
      const reader = new FileReader();
      reader.onload = (event: any) => {
        try {
          resolve(this.parseCsv(event.target.result));
        } catch {
          resolve(null);
        }
      };

      reader.readAsText(file);
    });
  }

  private parseCsv(csv: string): ParsedContent {
    const timeseries = csv
      .split('\n')
      .map(row => row.split(';'))
      .filter(row => row[0] !== '')
      .map(cells => <[string, string, string]>[cells[0], cells[1], cells[2]]);

    const timeseriesWithoutHeaders = timeseries.slice(1);
    return this.timeseriesParser.parse(timeseriesWithoutHeaders, timeseries[0][2]);
  }
}
