import {
  Component,
  ViewChild,
  ElementRef,
  OnDestroy,
  Input,
  AfterViewInit,
  TemplateRef,
  ContentChild,
  HostListener,
} from '@angular/core';
import { Subject, BehaviorSubject, fromEvent, from } from 'rxjs';
import { tap, takeUntil, debounceTime, filter, startWith } from 'rxjs/operators';
import * as Highcharts from 'highcharts';
import { chart } from 'highcharts';

import { ChartData, ChartPoint, ChartEvent, ChartEventAnnotation } from '../../interfaces';
import { chartConfig } from './line-chart.config';

@Component({
  selector: 'alp-line-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.scss'],
})
export class LineChartComponent implements AfterViewInit, OnDestroy {
  private onDestroy: Subject<void> = new Subject();

  // Component templates must have #eventTemplate property
  @ContentChild('eventTemplate')
  public eventTemplate: TemplateRef<any>;

  @Input('data')
  public data: Subject<ChartData> = new Subject();

  @Input('event')
  public event: Subject<ChartEvent> = new Subject();

  @Input('config')
  public customConfig: BehaviorSubject<Highcharts.Options> = new BehaviorSubject({});

  @Input('static')
  public static: boolean = true;

  @ViewChild('lineChart')
  public lineChart: ElementRef;

  @Input('pointChange')
  public pointChange: Subject<{ series: number; point: [number, number] }> = new Subject();

  @Input('selectedXChange')
  public selectedXChange: Subject<{ event: Event; x: number | null }> = new Subject();

  @Input('redrawChange')
  public redrawChange: Subject<void> = new Subject();

  public events: ChartEventAnnotation[] = [];

  private chart: Highcharts.ChartObject;

  private tickChange: Subject<number[]> = new Subject();
  private realTimeExtremeWidth: number;

  constructor() {
    Highcharts['Point'].prototype.highlight = function(event) {
      event = this.series.chart.pointer.normalize(event);
      this.onMouseOver();
      this.series.chart.xAxis[0].drawCrosshair(event, this);
    };
  }

  public ngAfterViewInit() {
    this.chart = chart(this.lineChart.nativeElement, {
      ...chartConfig,
      chart: {
        events: {
          render: () => {
            if (this.chart && this.getCurrentTicks().length > 0) {
              this.tickChange.next(this.getCurrentTicks());
            }
          },
        },
      },
    });

    // Update chart config on change
    this.customConfig
      .pipe(
        tap(config => {
          this.chart.update(config);
        }),
        takeUntil(this.onDestroy)
      )
      .subscribe();

    /**
     * Handle when data is passed to the Chart
     */
    this.data
      .pipe(
        tap(data => {
          if (data === null) {
            this.removeAllSeries();
            this.removeAllPlotBands();
          }
        }),
        filter(data => data !== null),
        tap(data => {
          if (!data.append) {
            this.removeAllSeries();
            this.removeAllPlotBands();
          }
          data.series.forEach((currentSeries, i) => {
            const existingSeriesIndex = this.chart.series.findIndex(series => currentSeries.name === series.name);

            if (currentSeries.secondary) {
              (<any>currentSeries).yAxis = 1;
            }

            if (data.append) {
              const toAdd = [];
              const toUpdate = [];
              currentSeries.data.forEach(entry => {
                if (!this.isAlreadyIn(existingSeriesIndex, entry)) {
                  toAdd.push(entry);
                } else if (data.updateOldValue) {
                  toUpdate.push(entry);
                }
              });

              toAdd.forEach(entry => {
                this.chart.series[existingSeriesIndex].addPoint(entry, false);
                this.pointChange.next({
                  series: existingSeriesIndex,
                  point: entry,
                });
              });

              if (toAdd.length > 0 && toUpdate.length > 0) {
                this.chart.redraw();
              }

              toUpdate.forEach(entry => {
                const index = this.chart.series[existingSeriesIndex].data.findIndex(
                  d => d !== null && d !== undefined && d.x === entry[0]
                );
                if (index !== -1) {
                  this.chart.series[existingSeriesIndex].data[index].update(entry, false);
                }
              });
            } else {
              this.chart.addSeries(currentSeries, false);
            }
          });

          this.setExtremes(data);

          // in real time the points in the past will be deleted
          // but only if we don't preserve the start of the extreme
          if (data.append && !data.staticExtreme) {
            this.removePointsBefore(data.to - this.realTimeExtremeWidth);
          }
        }),
        debounceTime(500),
        tap(() => {
          this.chart.redraw();
          this.redrawChange.next();
        }),
        takeUntil(this.onDestroy)
      )
      .subscribe();

    this.event
      .pipe(
        tap(result => {
          /**
           * remove already existing plot on the same place
           */
          const existingPlot = (this.chart.xAxis[0] as any).plotLinesAndBands.find(
            p => p.id === 'plotLine' && result.time === p.options.value
          );

          if (existingPlot) {
            existingPlot.destroy();
          }

          /**
           * adding a new plot
           */
          this.chart.xAxis[0].addPlotLine({
            color: '#42F44E',
            value: result.time,
            width: 2,
            id: 'plotLine',
            zIndex: 100,
            label: {
              text: result.text,
              rotation: 0,
              style: {
                color: '#afafaf',
              },
              y: 20,
            },
          });

          this.enableOnlyLastEventTooltip();

          this.events.push({
            ...result,
            point: {
              x: 0,
              y: 0,
            },
            visible: false,
          });
        })
      )
      .subscribe();

    this.tickChange
      .pipe(
        debounceTime(100),
        filter(ticks => ticks.length > 1),
        tap(ticks => {
          const width = (ticks[1] - ticks[0]) / 2; // calculate the plot's width from half of a tick
          this.renderPlotBands(ticks[0], ticks[ticks.length - 1], width);
          this.removeShiftedPlotlines();
        }),
        takeUntil(this.onDestroy)
      )
      .subscribe();

    /**
     * Resize the chart to support responsivness.
     */
    fromEvent(window, 'resize')
      .pipe(
        startWith(null),
        debounceTime(50),
        tap(() => {
          // const boundingClientRect = (<HTMLDivElement>this.lineChart.nativeElement).getBoundingClientRect();
          // TODO: This is ugly, but I cannot make the chart container fill it's correctly sized component host element.
          const boundingClientRect = document.getElementsByTagName('alp-line-chart')[0].getBoundingClientRect();

          this.resize(boundingClientRect.width, boundingClientRect.height);
        }),
        takeUntil(this.onDestroy)
      )
      .subscribe();
  }

  private enableOnlyLastEventTooltip(): void {
    const plotLines = (<any>this.chart.xAxis[0]).plotLinesAndBands.filter(p => p.id === 'plotLine');

    plotLines.filter((p, index) => index < plotLines.length - 1).forEach(p => {
      p.options.label.text = '';
      this.redrawPlotline(p);
    });
  }

  public selectX(event: Event, x: number) {
    let point;

    this.chart.series
      .filter((s: any) => s.points !== undefined)
      // using every, so premature loop breaking is possible, when we find the right point
      .every((series: any) => {
        const possiblePoint = series.points.find(p => p.x === x);
        if (possiblePoint) {
          point = possiblePoint;
          return false;
        }
        return true;
      });

    if (point) {
      point.highlight(event);
    }
  }

  private redrawPlotBands(): void {
    this.removeAllPlotBands();
    this.tickChange.next(this.getCurrentTicks());
  }

  public reflow(): void {
    this.chart.reflow();
  }

  public resize(width: number, height?: number): void {
    this.chart.update({
      chart: {
        width,
        height,
      },
    });
    this.redrawPlotBands();
  }

  public getSeries(): Highcharts.SeriesObject[] {
    return this.chart.series;
  }

  public getPointsAtX(x: number): { index: number; y: number | null }[] {
    return this.chart.series.map((s, index) => {
      const point = s.data.find(d => d !== null && d.x === x);
      return {
        index,
        y: point ? point.y : null,
      };
    });
  }

  public isEmpty(): boolean {
    return this.chart.series.every(curr => curr.data.length === 0);
  }

  public removeEvents(): void {
    this.chart.xAxis[0].removePlotLine('plotLine');
    this.events = [];
  }

  public getYAxis(): Highcharts.AxisObject[] {
    return this.chart.yAxis;
  }

  public addAxis(options: Highcharts.AxisOptions): void {
    this.chart.addAxis({
      ...options,
      title: {
        ...options.title,
        text: undefined,
      },
    });
  }

  private redrawPlotline(plotLine: any): void {
    const options = {
      ...plotLine.options,
    };

    plotLine.destroy();

    this.chart.xAxis[0].addPlotLine(options);
  }

  private isAlreadyIn(timeseriesIndex: number, entry: any[]): boolean {
    return (
      !this.chart.series[timeseriesIndex] ||
      this.chart.series[timeseriesIndex].options.data.filter(e => e[0] === entry[0]).length > 0
    );
  }

  private setExtremes(data: ChartData) {
    if (this.static) {
      // set the extremes on a static chart always to both ends of the data
      this.chart.xAxis[0].setExtremes(data.from, data.to, true);
    } else {
      // don't set extremes in append mode, when the max is smaller than it was
      // this means that the chart is in the present, and the data coming in is
      // in the past
      if (this.chart.xAxis[0].getExtremes().max > data.to) {
        return;
      }
      // at first we get the width of the extreme, which is the first and last date's difference
      // then keep this width for data later added
      if (!data.append) {
        this.realTimeExtremeWidth = data.to - data.from;
      }

      if (data.staticExtreme) {
        this.chart.xAxis[0].setExtremes(data.staticExtreme.from, data.staticExtreme.to, false);
      } else {
        this.chart.xAxis[0].setExtremes(data.to - this.realTimeExtremeWidth, data.to, false);
      }
    }
  }

  private removeShiftedPlotlines(): void {
    const border = (<any>this.chart.xAxis[0]).min;
    (<any>this.chart.xAxis[0]).plotLinesAndBands
      .filter(p => p.id === 'plotLine' && p.options.value < border)
      .forEach(p => p.destroy());
  }

  private removePointsBefore(date: number): void {
    this.chart.series.forEach(s => {
      if (!(<any>s).userOptions.preservePast) {
        const dataToRemove = s.data.filter(d => d !== null && d.x < date);
        const dataLength = dataToRemove.length;
        dataToRemove.forEach(d => d.remove(false));

        /**
         * Redraw the graph only when at least one point is removed.
         * The redraw must happen before the next series is checked, because highcharts will have inconsistent data
         */
        if (dataLength > 0) {
          this.chart.redraw();
        }
      }
    });
  }

  private removeAllSeries(): void {
    while (this.chart.series.length > 0) {
      this.chart.series[0].remove(true);
    }
  }

  private removeAllPlotBands(): void {
    const plotBandIds: string[] = (<any>this.chart.xAxis[0]).plotLinesAndBands
      .filter(pb => pb.id !== 'plotLine')
      .map(pb => pb.id);
    plotBandIds.forEach(id => this.chart.xAxis[0].removePlotBand(id));
  }

  private renderPlotBands(from: number, to: number, width: number): void {
    const plotBands: Highcharts.PlotBands[] = [];
    let oddOccurance = true;

    /**
     * Remove plotbands that are shifted out
     */
    const minShownTime = this.chart.xAxis[0].getExtremes().min;
    const shiftedOutPlotBandIds: string[] = (<any>this.chart.xAxis[0]).plotLinesAndBands
      .filter(p => p.options.to < minShownTime)
      .map(p => p.id);

    shiftedOutPlotBandIds.forEach(id => this.chart.xAxis[0].removePlotBand(id));

    /**
     * We generate the plotband objects for the whole range.
     */
    for (; from < to; from += width) {
      if (oddOccurance) {
        const id = `${from}`;
        if (!this.isPlotBandAdded(id)) {
          plotBands.push(this.generatePlotbandObject(id, from, from + width));
        }
      }
      oddOccurance = !oddOccurance;
    }

    plotBands.forEach(plotBand => this.chart.xAxis[0].addPlotBand(plotBand));
  }

  private isPlotBandAdded: (id: string) => boolean = id => {
    return (<any>this.chart.xAxis[0]).plotLinesAndBands.find(p => p.id === id) !== undefined;
  };

  private getCurrentTicks(): number[] {
    const ticks = (<any>this.chart.xAxis[0]).paddedTicks;
    return ticks ? ticks : [];
  }

  private generatePlotbandObject(id, from, to): Highcharts.PlotBands {
    const PLOT_BORDER_COLOR = '#DADBE1';
    const PLOT_BAND_COLOR = '#F8F8FA';

    return <Highcharts.PlotBands>{
      color: PLOT_BAND_COLOR,
      from: from,
      to: to,
      borderWidth: 1,
      borderColor: PLOT_BORDER_COLOR,
      id,
    };
  }

  public onSelectX(event: Event) {
    if (!this.chart.series[0] || !(<any>this.chart.series[0]).points) {
      return;
    }

    let selectedPoint;

    for (const series of this.chart.series) {
      selectedPoint = (<any>series).points.find(p => p.state && p.state === 'hover');

      if (selectedPoint) {
        break;
      }
    }

    if (selectedPoint) {
      this.selectedXChange.next({ event, x: selectedPoint.x });
    }
  }

  public handleMouseLeave(event: Event): void {
    this.selectedXChange.next({ event, x: null });
  }

  public hideTooltip(): void {
    (<any>this.chart).tooltip.hide();
  }

  public ngOnDestroy() {
    this.hideTooltip();
    this.onDestroy.next();
    this.onDestroy.unsubscribe();
  }
}
