import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { registerLocaleData, DecimalPipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DateAdapter, MatDialogModule } from '@angular/material';
import { NetworkModule, NetworkAuthService } from '@astron/network';
import { I18nModule } from '@astron/i18n';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import localeDeCH from '@angular/common/locales/de-CH';
import localeItCH from '@angular/common/locales/it-CH';

import { CustomDateAdapter } from './custom-date-adapter.class';
import { CoreRoutingModule } from './core-routing.module';

import { environment } from '../../environments/environment';

import { AppLoader } from './route-guards';
import { NotFoundViewComponent, MainViewComponent, PeakshavingPricerComponent } from './views';
import {
  LanguageSelectorComponent,
  StepperComponent,
  FileUploaderComponent,
  UnitConfirmationDialogComponent,
} from './components';
import { CoreComponent } from '@core/core.component';
import { SharedModule } from './shared.module';
import { FileGuesserService } from './services';
import { XlsxReaderService } from './services/xlsx-reader.service';
import { CsvReaderService } from './services/csv-reader.service';
import { TimeseriesParserService } from './services/timeseries-parser.service';
import { ChartsModule } from '../charts/charts.module';
import { PeakshavingPricerService } from './services/peakshaving-pricer.service';
import { LocalUIComponentsModule } from '../local-ui-elements/local-ui-components.module';

// en is registered by default
registerLocaleData(localeDeCH, 'de');
registerLocaleData(localeItCH, 'it');

@NgModule({
  declarations: [
    CoreComponent,
    NotFoundViewComponent,
    MainViewComponent,
    LanguageSelectorComponent,
    StepperComponent,
    FileUploaderComponent,
    UnitConfirmationDialogComponent,
    PeakshavingPricerComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    SharedModule,
    I18nModule.forRoot({
      dictionaryList: {
        path: '/assets/i18n',
        languages: ['en', 'de', 'it'],
        packages: ['common', 'core', 'peakshaving-pricer'],
      },
      defaultLocale: 'en',
      defaultDictionary: 'common',
      dateFormat: 'medium',
    }),
    NetworkModule.forRoot({
      baseUrl: environment.apiEndpoint,
    }),
    ChartsModule,
    MatDialogModule,
    LocalUIComponentsModule,
    CoreRoutingModule, // needs to be last, otherwise every other route is 404
  ],
  entryComponents: [UnitConfirmationDialogComponent],
  exports: [FormsModule, ReactiveFormsModule],
  providers: [
    {
      provide: NetworkAuthService,
      useValue: { token: null, authenticated: false },
    },
    { provide: DateAdapter, useClass: CustomDateAdapter },
    AppLoader,
    FileGuesserService,
    XlsxReaderService,
    CsvReaderService,
    TimeseriesParserService,
    PeakshavingPricerService,
    DecimalPipe,
  ],
  bootstrap: [CoreComponent],
})
export class CoreModule {}
