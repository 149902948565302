import { Component, HostListener } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { I18nService } from '@astron/i18n';

@Component({
  selector: 'alp-language-selector',
  templateUrl: 'language-selector.component.html',
  styleUrls: ['language-selector.component.scss'],
  animations: [
    trigger('state', [
      state('hide', style({ height: '39px', width: '41px' })),
      state('show', style({ height: '*', width: '*' })),
      transition('hide <=> show', [animate(150)]),
    ]),
  ],
})
export class LanguageSelectorComponent {
  public opened: boolean = false;

  constructor(public i18nService: I18nService) {}

  get availableLocales() {
    return this.i18nService.availableLocales.filter(locale => locale !== this.i18nService.locale);
  }

  public get state() {
    return this.opened ? 'show' : 'hide';
  }

  public toggle(event: MouseEvent) {
    this.opened = !this.opened;

    event.stopPropagation();
  }

  public selectLanguage(locale: string) {
    this.i18nService.changeLocale(locale);
  }

  @HostListener('document:click', ['$event'])
  public closeDropdown(event) {
    this.opened = false;
  }
}
