import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { startWith, map, tap } from 'rxjs/operators';
import { I18nService } from '@astron/i18n';

import { environment } from '../../../../environments/environment';

@Component({
  selector: 'alp-main-view',
  styleUrls: ['./main.component.scss'],
  templateUrl: './main.component.html',
})
export class MainViewComponent {
  public readonly environment = environment;

  public locale: Observable<string> = this.i18n.localeChanges.pipe(
    startWith(this.i18n.locale),
    // TODO: currently if locale is not `de` we fallback to EN, this must be removed when the the IT brochure is added.
    map(locale => `/assets/docs/Batteriepartnerschaft_${locale.toUpperCase() === 'DE' ? 'DE' : 'EN'}.pdf`)
  );

  constructor(private i18n: I18nService) {}
}
