import { DecimalPipe } from '@angular/common';
import { chartConfig } from '../../charts/components/line-chart/line-chart.config';
import { format } from 'date-fns';

export const getPoolingLineChartConfig: (decimalPipe: DecimalPipe, measurementUnit: string) => Highcharts.Options = (
  decimalPipe,
  measurementUnit
) => {
  // Wrap function to use it in non-arrow functions
  const formatNumber = (value: number) => {
    return decimalPipe.transform(value, undefined, 'de-CH');
  };

  return {
    ...chartConfig,
    tooltip: {
      formatter: function() {
        const pointsLabel = this.points
          .map(point => {
            return `<tr>
              <td class="chart-tooltip__title"></td>
              <td class="chart-tooltip__unit" style="font-weight: bold; text-align: right">${formatNumber(
                point.y
              )} kW</td>
              <td class="chart-tooltip__date">at ${format(this.x, 'YYYY-MM-DD HH:mm:ss')}</td>
            </tr>`;
          })
          .join('');

        return `
        <div class="chart-tooltip"
        style="font-family: VistaSansOTCE-Med; color: #053043; font-size: 12px; padding-left: 5px; padding-right: 5px;">
          <table>
            <tbody>
              ${pointsLabel}
            </tbody>
          </table>
        </div>`;
      },
      shared: true,
      outside: true,
      useHTML: true,
      borderWith: 1,
      padding: 0,
      borderRadius: 0,
      borderColor: '#053043',
      backgroundColor: '#ffffff',
      shape: 'square',
      shadow: false,
    },
    yAxis: {
      labels: {
        formatter: function() {
          return `${formatNumber(this.value)} ${measurementUnit}`;
        },
      },
    },
  };
};
