import { Component, OnInit, ViewChild, ElementRef, OnDestroy, Input } from '@angular/core';
import { Subject } from 'rxjs';
import { tap, withLatestFrom, switchMap, takeUntil } from 'rxjs/operators';
import { FileGuesserService } from '@core/services';
import { FileFormat } from '@core/enums';
import { TypedFile } from '@core/interfaces';

@Component({
  selector: 'alp-file-uploader',
  templateUrl: 'file-uploader.component.html',
  styleUrls: ['file-uploader.component.scss'],
})
export class FileUploaderComponent implements OnInit, OnDestroy {
  private onDestroy: Subject<void> = new Subject();

  @ViewChild('uploader')
  public uploader: ElementRef;

  public uploadClicked: Subject<any> = new Subject();

  public draggingFile: boolean = false;

  @Input('fileUploaded')
  public fileUploaded: Subject<TypedFile> = new Subject();

  @Input('validFileExtensions')
  public validFileExtensions: FileFormat[] = [];

  constructor(private fileGuesserService: FileGuesserService) {}

  ngOnInit() {
    window.addEventListener(
      'drop',
      e => {
        if (e) {
          e.preventDefault();
        }
      },
      false
    );

    this.setupUploadClickStream();
  }

  setupUploadClickStream() {
    this.uploadClicked
      .pipe(
        tap(event => {
          this.handleFile(event.target.files[0]);
          this.uploader.nativeElement.value = '';
        }),
        takeUntil(this.onDestroy)
      )
      .subscribe();
  }

  public dragOver(event) {
    this.draggingFile = true;
    event.preventDefault();
    event.stopPropagation();
  }

  public onDrop(event) {
    const files: FileList = event.dataTransfer.files;
    this.handleFile(files.item(0));
    this.draggingFile = false;
    event.preventDefault();
    event.stopPropagation();
  }

  public dragLeave(event) {
    this.draggingFile = false;
    event.preventDefault();
    event.stopPropagation();
  }

  private handleFile(file: File) {
    const typedFile = this.fileGuesserService.guessFileType(file, this.validFileExtensions);
    this.fileUploaded.next(typedFile);
  }

  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.unsubscribe();
  }
}
