import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { NotFoundViewComponent, PeakshavingPricerComponent } from './views';
import { MainViewComponent } from './views/main/main.component';
import { AppLoader } from './route-guards';

const routes: Routes = [
  {
    path: '',
    canActivate: [AppLoader],
    component: MainViewComponent,
    children: [
      {
        path: '',
        redirectTo: 'peakshaving-pricer',
        pathMatch: 'full',
      },
      {
        path: 'peakshaving-pricer/supplier/:id',
        component: PeakshavingPricerComponent,
      },
      {
        path: 'peakshaving-pricer',
        component: PeakshavingPricerComponent,
      },
      {
        path: '**',
        component: NotFoundViewComponent,
        pathMatch: 'full',
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class CoreRoutingModule {}
